body,
#root {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    font-family: "Chromatica", sans-serif;

    --fw-normal: 400;
    --fw-regular: var(--fw-normal);
    --fw-medium: 500;
    --fw-bold: 700;
    --fw-black: 900;
    --max-width: 1920px;
    --input-shadow: 4px;
    --avatar-shadow: 6px;
    --topbar-padding: 30px;
    --topbar-min-height: 32px;
    --space-s: 20px;
    --space-m: 40px;
    --space-l: 50px;
    --space-xl: var(--space-l);

    --border-color: #000;
    --form-error-color: #e64c25;
    --form-valid-color: #03a076;
    --shadow-thickness: 11px;
    --cream-color-000: #f5f3ef;
    --border-cream-color-000: #ebe7df;
    --cream-color-400: #f2eee8;
    --border-cream-color-400: #e9e2d8;
    --cream-color-900: #e7e1d8;
    --border-cream-color-900: #ddd5c8;
    --cream-color-800: #ece7e0;
    --border-cream-color-800: #e2dbd0;
    --cream-color-700: #efeae4;
    --border-cream-color-700: #e6ded4;
    --dark-color-900: #000;

    --color-200: #c0f6e0;
}

body {
    position: relative;
    background-size: cover;
    overflow-x: hidden;
}

header {
    display: none;
}

main {
    background: #FFFFFF !important;
}

.header .logo {
    width: 100px;
}


@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"), url(./assets/fonts/Helvetica.ttf) format("ttf");
}

@font-face {
    font-family: "Chromatica";
    src: local("Chromatica"), url(./assets/fonts/Chromatica-Regular.woff2) format("woff2");
}

* {
    font-family: "Chromatica", sans-serif;
}

.p-2 {
    padding: 16px;
}

.px-2 {
    padding-left: 16px;
    padding-right: 16px;
}

.py-2 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.d-flex {
    display: flex !important;
}

.d-block {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}

.d-center {
    justify-content: center;
}

.m-auto {
    margin: auto;
}

.t-center {
    text-align: center;
}

.mt-5 {
    margin-top: 44px;
}

.mt-4 {
    margin-top: 32px;
}

.mt-3 {
    margin-top: 24px;
}

.mt-2 {
    margin-top: 16px;
}

.ml-1 {
    margin-left: 8px;
}

.ml-2 {
    margin-left: 16px !important;
}

.ml-3 {
    margin-left: 24px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-2 {
    margin-bottom: 16px !important;
}

.mb-3 {
    margin-bottom: 24px !important;
}

.m-visible {
    display: none;
}

.d-visible {
    display: block;
}

.ant-layout-footer {
    padding: 0 !important;
}

@media only screen and (min-width: 300px) and (max-width: 900px){
    .m-visible {
        display: flex;
    }

    .d-visible {
        display: none !important;
    }
}
