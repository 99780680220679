#upload-form {
	textarea {
		font-size: 14px;
	}

	.ant-input-clear-icon {
		font-size: 16px;
	}

	input[type="file"] {
	    display: none;
	}

	.custom-file-upload {
		border-radius: 4px;
	    padding: 6.5px 25px;
	    border: 1px solid #d9d9d9;
	    cursor: pointer;		
		font-weight: 300;
	}

}