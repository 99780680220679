@import '../../variables';

.download-page .page-body {
    min-height: 370px;
    max-width: 500px;

    button {
        margin-top: 12px;
        width: 100%
    }

    .logout-btn {
        border-color: #f74040 !important;
        background: #ffffff !important;
        color: #f74040;
    }

    .d-key {
        border: 1px dashed #ddd;
        border-radius: 6px;
        padding: 4px 14px;
        color: #999999;
        display: flex;

        p {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: center;
            word-break: break-all;
        }

        span {
            cursor: pointer;
            font-size: 12px;
            margin: auto 0 auto 10px;
        }
    }

    .t-copied {
        font-size: 10px;
        height: 16px;
        text-align: center;
        margin-top: 4px;
        color: #999999;
        opacity: 0;

        &.show {
            opacity: 1;
            transition: opacity 1s;
        }
    }

    .ant-picker.ant-picker-disabled {
        background: #eaeaea80;
        cursor: initial;
    }

    .ant-input[disabled],
    .ant-picker-input > input[disabled] {
        cursor: initial;
        color: #000000;
    }

    .save-btn {
        background: #1890ff !important;
    }
}