.page-container {
    display: flex;
    min-height: 100vh;

    .page-body {
        margin: auto;
        padding: 30px;
        background: #ffffff;
        display: flex;
        max-width: 320px;
        width: 100%;
        position: relative;

        .loading-bar {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            z-index: 100;
            text-align: center;
            background: #7474742e;
        }

        h1 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 24px;
        }

        .primary-btn {
            background: #7DC763;
            border-color: transparent;
            font-size: 14px;
            line-height: 1;
            border-radius: 4px;
        }

        p {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .error {
            font-size: 14px !important;
            margin-top: 16px !important;
            color: #ff4d4f;
            margin: auto;
            text-align: center;
        }

        .ant-form-item-label {
            padding-bottom: 4px;
        }

        input {
            border-radius: 4px !important;
        }

        .ant-form-item {
            margin-bottom: 8px;
        }

        .ant-picker {
            width: 100%;
            border-radius: 4px;
        }

        .ant-form-item-explain-error {
            font-size: 12px;
            margin-top: 2px;
        }
    }

}

@media only screen and (min-width: 300px) and (max-width: 900px){
    .page-container {
        .page-body {
            width: 100%;
        }
    }
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 14px !important;    
    margin-bottom: 0 !important;
    margin-left: 38px !important;
}

.ant-notification-notice-icon {
    font-size: 20px !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 8px !important;
    word-break: break-all !important;
}

.ant-table-thead > tr > th {
    font-weight: bold !important;
}