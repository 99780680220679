.page-container.admin-board {
	.page-body {
		max-width: 800px;


		.titles {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;

			h1 {
				margin: 0;
			}
		}

		.anticon-delete {
			color: #f74040;
		}
	}

	form {
		width: 100%;
	}
}