@import '../../variables';

.navs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 0px 45px;
    background: #F7F7F7;

    img {
        width: 50px;
        height: 50px;
    }

    h1 {
        margin-bottom: 0;
        margin-right: 25px;
        font-size: 22px;
        line-height: 1.5;
    }

    .nav {
        display: flex;
        align-items: center;
    }

    .nav-item {
        font-size: 20px;
        line-height: 1.5;
        font-weight: bold;
        color: #000000;
        padding-right: 56px;
    }

    .auth-btn {
        font-size: 20px;
        font-weight: bold;
        color: #0288CC;
        margin-left: 25px;
        line-height: 1.5;
    }
}


@media only screen and (min-width: 300px) and (max-width: 1180px){

    .header {
        padding: 0 !important;
    }

    .navs {
        padding: 20px;

        .center {
            /*display: none;*/
            position: fixed;
            width: 306px;
            img {
                margin-top: 14px;
                margin-left: 10px;
            }
        }

        .nav {
            button {
                display: none;
            }

            .listIco {
                display: none;
            }
        }
    }
}