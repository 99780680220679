.auth-page {
    .page-body {

        .auth-form {
            margin: auto !important;
            width: 100%;

            label {
                font-weight: bold;
            }

            button {
                width: 100%;
            }

            .chk-txt {
                font-size: 12px;
                font-style: italic;
                text-align: center;
                margin: auto;
                margin-top: 12px;
            }

            hr {
                margin: 20px 0;
            }

            a {
                font-size: 14px;
            }

            .switch-btn {
                background: #1890ff;
            }
        }
    }
}